import type { RouteLocationNormalizedLoaded } from 'vue-router'

export interface UsePageMetaHelperConfig {
  name?: string
  title?: string
  description?: string
  image?: string
}

export const usePageMetaHelper = (
  route: RouteLocationNormalizedLoaded,
  metaConfig: UsePageMetaHelperConfig = {}
) => {
  const SEO = useRuntimeConfig().public.SEO
  const { $siteConfig } = useNuxtApp()

  const applyMeta = (config: UsePageMetaHelperConfig = {}) => {
    const {
      title = undefined,
      description = undefined,
      image = undefined,
      name = undefined,
    } = { ...$siteConfig.value, ...metaConfig, ...config }

    const seoTitle = title || SEO.TITLE
    const seoDescription = description || SEO.DESCRIPTION
    const seoImage = image
      ? `${image}/m/1200x630/smart`
      : `${SEO.URL}/share.jpg`
    const seoPath = SEO.URL + route.fullPath

    useHead({
      title: name,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: seoTitle,
        },
        {
          hid: 'description',
          name: 'description',
          content: seoDescription,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: seoDescription,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: seoImage,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: seoImage,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: seoPath,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: seoPath,
        },
      ],
    })
  }

  return { applyMeta }
}
